<template>
    <tr class="table__href" v-on:click="clickItem" v-on:contextmenu="openMenu">
        <td width="1%">
            <i class="material-icons">{{ file.type === 'folder' ? 'folder' : 'description' }}</i>
        </td>
        <td>{{ file.name }}</td>
        <td>{{ moment(file.updated_at, "YYYY-MM-DD HH:mm:ss").format('DD.MM.YYYY, HH:mm') + ' Uhr' }}</td>
        <td>{{ moment(file.created_at, "YYYY-MM-DD HH:mm:ss").format('DD.MM.YYYY, HH:mm') + ' Uhr' }}</td>
        <td class="table__options">
            <dropdown buttonSize="small" ref="dropdown">
                <a
                    href="#view"
                    class="menu__item"
                    v-on:click="openFolder"
                    v-if="file.type === 'folder'"
                >Öffnen</a>
                <a
                    href="#view"
                    class="menu__item"
                    v-on:click="viewFile"
                    v-if="file.type === 'file'"
                >Anzeigen</a>
                <a
                    href="#edit"
                    class="menu__item"
                    v-on:click="editFile"
                    v-if="file.type === 'file'"
                >Bearbeiten</a>
                <div class="menu__divider"></div>
                <a href="#rename" class="menu__item" v-on:click="renameFile">Umbenennen</a>
                <div class="menu__divider"></div>
                <a href="#delete" class="menu__item" v-on:click="deleteFile">Löschen</a>
            </dropdown>
        </td>
    </tr>
</template>

<script>
import moment from "moment"
import Dropdown from "@/components/layout/Dropdown"

export default {
    props: ["file"],
    components: {
        Dropdown
    },
    methods: {
        moment,
        clickItem() {
            if (this.file.type === "folder") {
                this.$router.push({
                    name: "folder.index",
                    params: { folderId: this.file.id }
                })
            } else {
                this.$router.push({
                    name: "file.view",
                    params: {
                        folderId: this.file.parent_id,
                        fileId: this.file.id
                    }
                })
            }
        },
        openMenu(e) {
            e.preventDefault()
            this.$refs.dropdown.open({ x: e.clientX, y: e.clientY })
        },
        openFolder(e) {
            e.preventDefault()
            e.stopPropagation()
            this.$router.push({
                name: "folder.index",
                params: { folderId: this.file.id }
            })
        },
        viewFile(e) {
            e.preventDefault()
            e.stopPropagation()
            this.$router.push({
                name: "file.view",
                params: { folderId: this.file.parent_id, fileId: this.file.id }
            })
        },
        editFile(e) {
            e.preventDefault()
            e.stopPropagation()
            this.$router.push({
                name: "file.edit",
                params: { folderId: this.file.parent_id, fileId: this.file.id }
            })
        },
        renameFile(e) {
            e.preventDefault()
            e.stopPropagation()
            this.$root.$emit("file-rename", this.file)
            this.$refs.dropdown.close()
        },
        deleteFile(e) {
            e.preventDefault()
            e.stopPropagation()
            this.$root.$emit("file-delete", this.file)
            this.$refs.dropdown.close()
        }
    }
}
</script>
