<template>
    <div class="content content--flush content--flex content--stretch">
        <div class="content__left">
            <file-edit v-bind:file="file" v-on:file-edit-input="updateContent" />
        </div>
        <div class="content__right">
            <file-view v-bind:file="file"/>
        </div>
        <delete-modal v-if="deleteModal" v-bind:file="file" v-on:modal-close="deleteModal = false" v-on:modal-delete="deleteFile" />
    </div>
</template>

<script>
import FileEdit from '@/components/file/FileEdit'
import FileView from "@/components/file/FileView"
import DeleteModal from "@/components/modals/DeleteModal"

export default {
    components: {
        FileEdit,
        FileView,
        DeleteModal
    },
    data() {
        return {
            file: {},
            deleteModal: false
        }
    },
    methods: {
        backToView() {
            this.$router.replace({
                name: "file.view",
                params: this.$route.params.fileId
            })
        },
        backToFolder() {
            this.$router.replace({
                name: 'folder.index',
                params: {
                    folderId: this.$route.params.folderId || 0
                }
            })
        },
        loadFile() {
            this.$store.dispatch('auth/get', {
                url: `/v1/item/${this.$route.params.fileId}`
            })
            .then(res => {
                this.file = res.data
                this.$root.$emit('file-edit-load', this.file)
            }).catch(() => {
                this.$root.$emit('alert', {
                    status: 'danger',
                    message: 'Datei konnte nicht geladen werden'
                })
                this.backToFolder()
            })
        },
        setName(name) {
            this.$set(this.file, 'name', name)
        },
        updateContent(content) {
            this.$set(this.file, 'content', content)
        },
        saveFile() {
            if (!this.file.name.length) {
                this.$root.$emit('alert', {
                    status: 'danger',
                    message: 'Es wurde kein Name angegeben'
                })
                return
            }
            this.$set(this.file, 'content', this.file.content.trim() + "\n")
            this.$store.dispatch('auth/post', {
                url: `/v1/item/${this.$route.params.fileId}`,
                data: {
                    parent_id: this.$route.params.folderId,
                    type: 'file',
                    name: this.file.name,
                    content: this.file.content
                }
            })
            // axios.post('/v1/item/' + this.$route.params.fileId, {
            //     parent_id: this.$route.params.folderId,
            //     type: 'file',
            //     name: this.file.name,
            //     content: this.file.content
            // })
            .then(() => {
                this.$root.$emit('alert', {
                    status: 'success',
                    message: 'Datei wurde erfolgreich gespeichert'
                })
            })
            .catch(() => {
                this.$root.$emit('alert', {
                    status: 'danger',
                    message: 'Datei konnte nicht gespeichert werden'
                })
            })
        },
        openDeleteModal() {
            this.deleteModal = true
        },
        deleteFile() {
            this.deleteModal = false
            this.$store.dispatch('auth/delete', {
                url: `/v1/item/${this.$route.params.fileId}`
            })
            .then(() => {
                this.$root.$emit('alert', {
                    status: 'success',
                    message: 'Datei wurde gelöscht'
                })
                this.backToFolder()
            })
            .catch(() => {
                this.$root.$emit('alert', {
                    status: 'danger',
                    message: 'Datei konnte nicht gelöscht werden'
                })
            })
        }
    },
    mounted() {
        this.$root.$on("topbar-back", () => this.backToView())
        this.$root.$on('topbar-save', () => this.saveFile())
        this.$root.$on("topbar-delete", () => this.openDeleteModal())
        this.$root.$on("topbar-name-input", name => this.setName(name))

        this.loadFile()
    },
    beforeDestroy() {
        this.$root.$off("topbar-back")
        this.$root.$off("topbar-save")
        this.$root.$off("topbar-delete")
        this.$root.$off("topbar-name-input")
    }
}
</script>
