<template>
    <div class="content content--flush content--flex content--stretch">
        <div class="content__left">
            <file-edit v-bind:file="file" v-on:file-edit-input="updateContent" />
        </div>
        <div class="content__right">
            <file-view v-bind:file="file"/>
        </div>
    </div>
</template>

<script>
import FileEdit from '@/components/file/FileEdit'
import FileView from "@/components/file/FileView"

export default {
    components: {
        FileEdit,
        FileView
    },
    data() {
        return {
            file: {
                name: '',
                content: ''
            }
        }
    },
    methods: {
        backToFolder() {
            this.$router.replace({
                name: 'folder.index',
                params: {
                    folderId: this.$route.params.folderId || 0
                }
            })
        },
        setName(name) {
            this.$set(this.file, 'name', name)
        },
        updateContent(content) {
            this.$set(this.file, 'content', content)
        },
        saveFile() {
            if (!this.file.name.length) {
                this.$root.$emit('alert', {
                    status: 'danger',
                    message: 'Es wurde kein Name angegeben'
                })
                return
            }
            this.$set(this.file, 'content', this.file.content.trim() + "\n")
            this.$store.dispatch('auth/post', {
                url: `/v1/item`,
                data: {
                    parent_id: this.$route.params.folderId || 0,
                    type: 'file',
                    name: this.file.name,
                    content: this.file.content
                }
            })
            .then(res => {
                this.$root.$emit('alert', {
                    status: 'success',
                    message: 'Datei wurde erfolgreich angelegt'
                })
                this.$router.push({
                    name: 'file.edit',
                    params: {
                        folderId: this.$route.params.folderId || 0,
                        fileId: res.data.id
                    }
                })
            })
            .catch(() => {
                this.$root.$emit('alert', {
                    status: 'danger',
                    message: 'Datei konnte nicht angelegt werden'
                })
                this.backToFolder()
            })
        }
    },
    mounted() {
        this.$root.$on("topbar-back", () => this.backToFolder())
        this.$root.$on('topbar-save', () => this.saveFile())
        this.$root.$on("topbar-name-input", name => this.setName(name))

        this.name = ''
        this.$root.$emit('file-create-clear-name')
    },
    beforeDestroy() {
        this.$root.$off("topbar-back")
        this.$root.$off("topbar-save")
        this.$root.$off("topbar-name-input")
    }
}
</script>
