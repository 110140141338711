<template>
    <textarea class="editor" ref="editor" v-bind:value="file.content" v-on:keydown="keydownEvent" v-on:input="inputEvent" spellcheck="false"></textarea>
</template>

<script>
import _ from "lodash"

export default {
    props: ['file'],
    data() {
        return {
            tab: "    "
        }
    },
    methods: {
        keydownEvent(e) {
            if (e.keyCode === 9) {
                this.tabKey(e)
            } else if (e.keyCode === 8) {
                this.backspaceKey(e)
            }
        },
        tabKey(e) {
            e.preventDefault()

            // Text is seleted
            if (e.target.selectionStart !== e.target.selectionEnd) {
                if (e.shiftKey) {
                    this.outdentCode(e.target)
                } else {
                    this.indentCode(e.target)
                }
            } else {
                if (e.shiftKey) {
                    this.outdentCode(e.target)
                } else {
                    this.addTab(e.target)
                }
            }
            //localMa = e.target.value
            this.fireInputEvent(e.target)
        },
        backspaceKey(e) {
            // No text selected
            if (e.target.selectionStart === e.target.selectionEnd && this.checkTab(e.target)) {
                e.preventDefault()
                this.removeTab(e.target)
                //localMa = e.target.value
                this.$el.dispatchEvent(document.createEvent('input'))
                this.fireInputEvent(e.target)
            }
        },
        findStartOfLine(value, pos) {
            let offset = -1
            while (!/\r?\n/g.test(value.substring(pos + offset, pos)) && pos + offset >= 0) {
                offset--
            }
            return pos + offset + 1
        },
        findEndOfLine(value, pos) {
            let offset = 1
            while (!/\r?\n/g.test(value.substring(pos + offset, pos)) && pos + offset <= value.length) {
                offset++
            }
            return pos + offset - 1
        },
        indentCode(elm) {
            const cursorStartPos = elm.selectionStart
            const cursorEndPos = elm.selectionEnd
            const startOfLine = this.findStartOfLine(elm.value, elm.selectionStart)
            const endOfLine = this.findEndOfLine(elm.value, elm.selectionEnd)
            const preSelection = elm.value.substring(0, startOfLine)
            const selection = elm.value.substring(startOfLine, endOfLine)
            const postSelection = elm.value.substring(endOfLine)
            const lines = selection.split('\n')

            const indentedLines = []
            let cursorOffset = 0
            for (const n in lines) {
                const line = lines[n]
                if (line === "") {
                    indentedLines.push(line)
                } else {
                    indentedLines.push(this.tab + line)
                    cursorOffset += this.tab.length
                }
            }

            elm.value = preSelection + indentedLines.join('\n') + postSelection

            // Keep selection
            elm.selectionStart = cursorStartPos + (lines[0] === "" ? 0 : this.tab.length)
            elm.selectionEnd = cursorEndPos + cursorOffset
        },
        outdentCode(elm) {
            const cursorStartPos = elm.selectionStart
            const cursorEndPos = elm.selectionEnd
            const startOfLine = this.findStartOfLine(elm.value, elm.selectionStart)
            const endOfLine = this.findEndOfLine(elm.value, elm.selectionEnd)
            const preSelection = elm.value.substring(0, startOfLine)
            const selection = elm.value.substring(startOfLine, endOfLine)
            const postSelection = elm.value.substring(endOfLine)
            const lines = selection.split('\n')

            const outdentedLines = []
            let cursorOffset = 0
            for (const n in lines) {
                const line = lines[n]
                if (line.substring(0, this.tab.length) === this.tab) {
                    outdentedLines.push(line.substring(this.tab.length))
                    cursorOffset += this.tab.length
                } else {
                    outdentedLines.push(line)
                }
            }

            elm.value = preSelection + outdentedLines.join('\n') + postSelection

            // Keep selection
            elm.selectionStart = cursorStartPos - (lines[0].substring(0, this.tab.length) === this.tab ? this.tab.length : 0)
            elm.selectionEnd = cursorEndPos - cursorOffset
        },
        checkTab(elm) {
            return elm.value.substring(elm.selectionStart - this.tab.length, elm.selectionStart) === this.tab
        },
        addTab(elm) {
            const cursorPos = elm.selectionStart
            elm.value = elm.value.substring(0, cursorPos) + this.tab + elm.value.substring(cursorPos)
            elm.selectionEnd = elm.selectionStart = cursorPos + this.tab.length
        },
        removeTab(elm) {
            const startOfTab = elm.selectionStart - this.tab.length
            elm.value = elm.value.substring(0, startOfTab) + elm.value.substring(elm.selectionStart)
            elm.selectionStart = elm.selectionEnd = startOfTab
        },
        fireInputEvent(elm) {
            elm.dispatchEvent(new Event('input'))
        },
        inputEvent: _.debounce(function(e) {
            this.$emit('file-edit-input', e.target.value.replace(/\t/g, this.tab))
        }, 300)
    },
    mounted() {
        // TODO: Add key shortcuts
        this.$refs.editor.focus()
    },
    beforeDestroy() {
        // TODO: Remove key shortcuts
    }
}
</script>