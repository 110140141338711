<template>
    <div class="menu" v-bind:class="{ 'menu--open': menuOpen }">
        <button
            type="button"
            v-bind:class="'button ' + (buttonStyle ? 'button--' + buttonStyle : '') + ' button--icon ' + (buttonSize ? 'button--' + buttonSize : '') + ' button--flat'"
            v-on:click="toggleMenu"
        >
            <i class="material-icons">{{ icon }}</i>
        </button>
        <div class="menu__items">
            <slot/>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        buttonStyle: {
            type: String,
            default: "default"
        },
        buttonSize: {
            type: String,
            default: ""
        },
        icon: {
            type: String,
            default: "more_vert"
        },
        pos: Object
    },
    data() {
        return {
            menuOpen: false,
            verticalOffset: 4
        }
    },
    methods: {
        toggleMenu(e) {
            e.stopPropagation()
            e.preventDefault()
            this.menuOpen ? this.close() : this.open()
        },
        open(pos) {
            this.closeAll()
            pos = pos || this.pos
            this.pageXOffset = window.pageXOffset
            this.pageYOffset = window.pageYOffset
            this.$el.classList.add("menu--open")
            const menuItems = this.$el.querySelector(".menu__items")
            // const rect = menuItems.getBoundingClientRect()
            const button = this.$el.querySelector(".button")
            const buttonRect = button.getBoundingClientRect()
            const openingMethod = pos ? "context" : "button"

            if (openingMethod === "button") {
                pos = {
                    x: buttonRect.right,
                    y: buttonRect.bottom + this.verticalOffset
                }
            }

            // Prevent menu from clipping out horizontally
            if (menuItems.clientWidth + pos.x > window.innerWidth) {
                pos.x -= menuItems.clientWidth
            }

            // Prevent menu from clipping out vertically
            if (menuItems.clientHeight + pos.y > window.innerHeight) {
                pos.y -= menuItems.clientHeight
                if (openingMethod === "button") {
                    pos.y -= button.clientHeight + 2 * this.verticalOffset
                }
            }

            document.addEventListener("click", this.bodyClickEvent)

            menuItems.style.transform =
                "translate(" + pos.x + "px, " + pos.y + "px)"
            this.menuOpen = true
        },
        close() {
            document.removeEventListener("click", this.bodyClickEvent)
            this.$el.querySelector(".menu__items").style.transform = ""
            this.menuOpen = false
        },
        closeAll() {
            document.querySelectorAll(".menu").forEach(elm => {
                elm.__vue__.close()
            })
        },
        bodyClickEvent(e) {
            e.stopPropagation()
            this.close()
        }
    },
    mounted() {
        // Prevent menu from closing if clicked
        this.$el.addEventListener("click", e => e.stopPropagation())
    },
    beforeDestroy() {
        this.$el.removeEventListener("click", e => e.stopPropagation())
    }
}
</script>
