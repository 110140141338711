<template>
    <div class="modal modal--open">
        <div class="modal__dialog">
            <div class="modal__header">
                <div class="modal__title">Umbenennen</div>
                <button class="button button--icon button--flat" v-on:click="close">
                    <i class="material-icons">clear</i>
                </button>
            </div>
            <div class="modal__body">
                <div class="form">
                    <div class="form__item">
                        <label for="folderName">Name</label>
                        <input type="text" ref="name" id="folderName" v-model="name" v-on:keyup.enter="save">
                    </div>
                </div>
            </div>
            <div class="modal__footer">
                <button type="button" class="button button--primary button--flat" v-on:click="save">Umbenennen</button>
                <button type="button" class="button button--default button--flat" v-on:click="close">Abbrechen</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['file'],
    data() {
        return {
            name: ""
        }
    },
    methods: {
        save() {
            this.$emit('modal-save', this.file, this.name)
        },
        close() {
            this.$emit('modal-close')
        }
    },
    mounted() {
        this.name = this.file.name
        this.$refs.name.focus()
    }
}
</script>
