<template>
    <div class="markdown" v-html="markdown"></div>
</template>

<script>
import showdown from "showdown"
import showdownHighlight from "showdown-highlight"

const converter = new showdown.Converter({
    extensions: [showdownHighlight]
})
converter.setFlavor("github")
converter.setOption("smoothLivePreview", "true")
converter.setOption("openLinksInNewWindow", "true")

export default {
    props: ['file'],
    computed: {
        markdown() {
            return converter.makeHtml(this.file.content)
        }
    }
}
</script>
