<template>
    <div class="home">
        <file-list v-bind:files="files"/>
        <new-folder-modal v-if="newFolderModal" v-on:modal-close="newFolderModal = false" v-on:modal-save="createFolder" />
        <rename-modal v-if="renameModal" v-bind:file="actionFile" v-on:modal-close="renameModal = false" v-on:modal-save="renameFile" />
        <delete-modal v-if="deleteModal" v-bind:file="actionFile" v-on:modal-close="deleteModal = false" v-on:modal-delete="deleteFile" />
    </div>
</template>

<script>
// @ is an alias to /src
import FileList from "@/components/file/FileList"
import NewFolderModal from "@/components/modals/NewFolderModal"
import RenameModal from "@/components/modals/RenameModal"
import DeleteModal from "@/components/modals/DeleteModal"

export default {
    components: {
        FileList,
        NewFolderModal,
        RenameModal,
        DeleteModal
    },
    data() {
        return {
            files: [],
            actionFile: null,
            newFolderModal: false,
            renameModal: false,
            deleteModal: false
        }
    },
    methods: {
        backToFolder(force) {
            if (force) {
                this.$router.replace({ name: 'folder.index' })
            }
            if (!this.$route.params.folderId) {
                return
            }
            this.$store.dispatch('auth/get', {
                url: `/v1/item/${(this.$route.params.folderId || 0)}`
            })
            .then(res => {
                this.$router.replace({
                    name: "folder.index",
                    params: {
                        folderId: res.data.parent_id || 0
                    }
                })
            })
            .catch(() => {
                this.$router.replace({ name: 'folder.index' })
            })
        },
        loadFiles() {
            this.$store.dispatch('auth/get', {
                url: `/v1/items/${(this.$route.params.folderId || 0)}`
            })
            .then(res => this.files = res.data)
            .catch(err => {
                this.$root.$emit('alert', {
                    status: 'danger',
                    message: 'Ordner konnte nicht ' + (err.response.status === 404 ? 'gefunden': 'geladen') + ' werden'
                })
                this.backToFolder(true)
            })
        },
        createFile() {
            this.$router.push({
                name: 'file.create',
                params: {
                    folderId: this.$route.params.folderId || 0
                }
            })
        },
        openCreateFolderModal() {
            this.newFolderModal = true
        },
        openRenameModal(file) {
            this.actionFile = file
            this.renameModal = true
        },
        openDeleteModal(file) {
            this.actionFile = file
            this.deleteModal = true
        },
        createFolder(name) {
            if (!name.length) {
                this.$root.$emit('alert', {
                    status: 'danger',
                    message: 'Es wurde kein Name angegeben'
                })
                return
            }
            this.newFolderModal = false
            this.actionFile = {}
            this.$store.dispatch('auth/post', {
                url: '/v1/item',
                data: {
                    parent_id: this.$route.params.folderId || 0,
                    type: 'folder',
                    name: name
                }
            })
            .then(() => {
                this.$root.$emit('alert', {
                    status: 'success',
                    message: 'Ordner angelegt'
                })
                this.loadFiles()
            })
            .catch(() => {
                this.$root.$emit('alert', {
                    status: 'danger',
                    message: 'Ordner konnte nicht angelegt werden'
                })
            })
        },
        renameFile(file, name) {
            if (!name.length) {
                this.$root.$emit('alert', {
                    status: 'danger',
                    message: 'Es wurde kein Name angegeben'
                })
                return
            }
            this.renameModal = false
            this.$store.dispatch('auth/post', {
                url: `/v1/item/${file.id}`,
                data: {
                    name
                }
            })
            .then(() => {
                this.$root.$emit('alert', {
                    status: 'success',
                    message: (file.type === 'folder' ? 'Ordner' : 'Datei') + ' umbenannt'
                })
                this.loadFiles()
            })
            .catch(() => {
                this.$root.$emit('alert', {
                    status: 'danger',
                    message: (file.type === 'folder' ? 'Ordner' : 'Datei') + ' konnte nicht umbenannt werden'
                })
            })
        },
        deleteFile(file) {
            this.deleteModal = false
            this.$store.dispatch('auth/delete', {
                url: `/v1/item/${file.id}`
            })
            .then(() => {
                this.$root.$emit('alert', {
                    status: 'success',
                    message: (file.type === 'folder' ? 'Ordner' : 'Datei') + ' wurde gelöscht'
                })
                this.loadFiles()
            })
            .catch(() => {
                this.$root.$emit('alert', {
                    status: 'danger',
                    message: (file.type === 'folder' ? 'Ordner' : 'Datei') + ' konnte nicht gelöscht werden'
                })
            })
        }
    },
    mounted() {
        // Registering Listeners
        this.$root.$on("topbar-back", () => this.backToFolder())
        this.$root.$on("topbar-new-file", () => this.createFile())
        this.$root.$on("topbar-new-folder", () => this.openCreateFolderModal())
        this.$root.$on("file-rename", file => this.openRenameModal(file))
        this.$root.$on("file-delete", file => this.openDeleteModal(file))

        this.loadFiles()
    },
    beforeDestroy() {
        // Removing Listeners
        this.$root.$off("topbar-back")
        this.$root.$off("topbar-new-file")
        this.$root.$off("topbar-new-folder")
        this.$root.$off("file-rename")
        this.$root.$off("file-delete")
    }
}
</script>
