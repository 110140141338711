<template>
    <div class="content">
        <file-view v-bind:file="file"/>
        <delete-modal v-if="deleteModal" v-bind:file="file" v-on:modal-close="deleteModal = false" v-on:modal-delete="deleteFile" />
    </div>
</template>

<script>
import FileView from "@/components/file/FileView"
import DeleteModal from "@/components/modals/DeleteModal"

export default {
    components: {
        FileView,
        DeleteModal
    },
    data() {
        return {
            file: {},
            deleteModal: false
        }
    },
    methods: {
        backToFolder() {
            this.$router.replace({
                name: "folder.index",
                params: {
                    folderId: this.$route.params.folderId
                }
            })
        },
        loadFile() {
            this.$store.dispatch('auth/get', {
                url: `/v1/item/${this.$route.params.fileId}`
            })
            .then(res => (this.file = res.data))
            .catch(() => {
                this.$root.$emit('alert', {
                    status: 'danger',
                    message: 'Datei konnte nicht geladen werden'
                })
                this.backToFolder()
            })
        },
        editFile() {
            this.$router.push({
                name: "file.edit",
                params: {
                    fileId: this.$route.params.fileId
                }
            })
        },
        openDeleteModal() {
            this.deleteModal = true
        },
        deleteFile() {
            this.deleteModal = false
            this.$store.dispatch('auth/delete', {
                url: `/v1/item/${this.$route.params.fileId}`
            })
            .then(() => {
                this.$root.$emit('alert', {
                    status: 'success',
                    message: 'Datei wurde gelöscht'
                })
                this.backToFolder()
            })
            .catch(() => {
                this.$root.$emit('alert', {
                    status: 'danger',
                    message: 'Datei konnte nicht gelöscht werden'
                })
            })
        }
    },
    mounted() {
        // Registering Listeners
        this.$root.$on("topbar-back", () => this.backToFolder())
        this.$root.$on("topbar-edit", () => this.editFile())
        this.$root.$on("topbar-delete", () => this.openDeleteModal())

        // Fetching File
        this.loadFile()
    },
    beforeDestroy() {
        // Removing Listeners
        this.$root.$off("topbar-back")
        this.$root.$off("topbar-edit")
        this.$root.$off("topbar-delete")
    }
}
</script>
