<template>
    <div class="modal modal--open">
        <div class="modal__dialog">
            <div class="modal__header">
                <div class="modal__title">Löschen</div>
                <button class="button button--icon button--flat" v-on:click="close">
                    <i class="material-icons">clear</i>
                </button>
            </div>
            <div class="modal__body">
                Soll {{ file.type === 'folder' ? 'der Ordner' : 'die Datei'}} <b>{{ file.name }}</b> wirklich gelöscht werden?
            </div>
            <div class="modal__footer">
                <button type="button" class="button button--danger button--flat" v-on:click="deleteItem">Löschen</button>
                <button type="button" class="button button--default button--flat" v-on:click="close">Abbrechen</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['file'],
    methods: {
        deleteItem() {
            this.$emit('modal-delete', this.file)
        },
        close() {
            this.$emit('modal-close')
        }
    }
}
</script>
