<template>
    <table class="table table--large" v-bind:class="{ 'table--hover': files.length }">
        <thead>
            <tr>
                <th></th>
                <th>Name</th>
                <th>Änderungsdatum</th>
                <th>Erstelldatum</th>
                <th class="table__options"></th>
            </tr>
        </thead>
        <tbody>
            <file-list-row v-bind:key="file.id" v-for="file in files" v-bind:file="file"/>
            <tr v-if="!files.length">
              <td class="text-center text-muted" colspan="5">Keine Einträge vorhanden</td>
            </tr>
        </tbody>
    </table>
</template>

<script>
import FileListRow from './FileListRow.vue'

export default {
    components: {
        FileListRow
    },
    props: ['files']
}
</script>
